import { DEFAULT_PAGE_SIZE, Dataset, DtSort } from '@/components/data-table/helpers';
import {
  deleteOne,
  findAll,
  findAllAndCount,
  findOne,
  getAccessListOidParams,
  getOidParam,
  getOidParams,
  getOrgFilter,
  getSort,
  insertOne,
  patchOne,
  update,
} from './atlas-data-api.service';
import { PolicyModel, PortalConfig, PublicServiceConfig } from '../models/policy.model';
import { JsonObject } from '../helpers';
import { getGroupsByIds, getGroupsByPolicyId, patchGroup } from './group.service';
import { cloneDeep, flatten, uniq } from 'lodash';
import { DeleteResponse, UpdateResponse } from '.';
import { ContextProviderMeta } from '../models/context-provider';
import { getProvidersByIds } from './context-provider.service';
import { PrivateModelModel } from '../models/private-model.model';
import { ContextProviderModel } from '../models/context-provider/context-provider.model';
import { getPrivateModelsByIds } from './private-model.service';
import { AgentMeta, AgentModel } from '../models/agent.model';
import { getAgentsByIds } from './agent.service';

const COLLECTION = 'policies';

export type PolicyMetaResult = [
  Map<string, ContextProviderMeta>,
  Map<string, PrivateModelModel>,
  Map<string, AgentMeta>,
];

export const getGroupPolicies = async (
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  dtSort?: DtSort
  //   dtFilter?: DtFilter
): Promise<Dataset<PolicyModel>> => {
  const sort = getSort(dtSort) || { name: 1 };

  const skip = page * pageSize;
  const filter = getOrgFilter();
  filter.scope = 'group';

  const { documents, total } = await findAllAndCount(COLLECTION, filter, pageSize, skip, sort);

  const rows: PolicyModel[] = documents.map((data) => new PolicyModel(data));

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getPolicyById = async (policyId: string): Promise<PolicyModel | null> => {
  const params = {
    filter: {
      _id: { $oid: policyId },
      ...getOrgFilter(),
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new PolicyModel(response) : null;
};

export const getPoliciesByGroupId = async (groupId: string): Promise<PolicyModel[]> => {
  const params = {
    filter: {
      groups: { $oid: groupId },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new PolicyModel(data)) : [];
};

export const getPoliciesByGroupIds = async (groupIds: string[]): Promise<PolicyModel[]> => {
  const params = {
    filter: {
      groups: { $elemMatch: { $in: getOidParams(groupIds) } },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new PolicyModel(data)) : [];
};

export const getOrgPolicy = async (): Promise<PolicyModel | null> => {
  const params = {
    filter: {
      scope: 'org',
      ...getOrgFilter(),
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new PolicyModel(response) : null;
};

export const getPoliciesByModelIds = async (modelIds: string[]): Promise<PolicyModel[]> => {
  const modelOids = getOidParams(modelIds);

  const params = {
    filter: {
      $or: [
        { 'portal.availablePrivateModelIds': { $elemMatch: { $in: modelOids } } },
        { 'portal.defaultModelId': { $in: modelOids } },
      ],
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response?.length ? response.map((data) => new PolicyModel(data)) : [];
};

export const getPoliciesByDatasourceIds = async (
  datasourceIds: string[]
): Promise<PolicyModel[]> => {
  const params = {
    filter: {
      ['contextDataSources.enabled']: { $elemMatch: { $in: getOidParams(datasourceIds) } },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new PolicyModel(data)) : [];
};

export const getPoliciesByAgentIds = async (agentIds: string[]): Promise<PolicyModel[]> => {
  const params = {
    filter: {
      ['assistants.enabled']: { $elemMatch: { $in: getOidParams(agentIds) } },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new PolicyModel(data)) : [];
};

export const createGroupPolicy = async (policy: PolicyModel): Promise<string | null> => {
  const { orgId } = getOrgFilter();

  const document = {
    ...policy._props,
    scope: 'group',
    orgId,
  };

  return insertOne(COLLECTION, document);
};

export const patchPolicy = async (policyId: string, data: JsonObject): Promise<boolean> => {
  const filter = {
    _id: { $oid: policyId },
    ...getOrgFilter(),
  };

  const patchData = cloneDeep(data);

  if (patchData?.portal) {
    const portalConfig = patchData?.portal as PortalConfig;

    if (!Array.isArray(portalConfig.availablePrivateModelIds)) {
      portalConfig.availablePrivateModelIds = [];
    }

    // convert defaultModelId to oid from string
    const defaultModelId = portalConfig.defaultModelId;
    if (defaultModelId) {
      (patchData.portal as JsonObject).defaultModelId = getOidParam(defaultModelId);

      // ensure the default is listed as available
      if (!portalConfig.availablePrivateModelIds.includes(defaultModelId)) {
        portalConfig.availablePrivateModelIds.push(defaultModelId);
      }
    }

    // convert availablePrivateModelIds to oids from strings
    if (portalConfig.availablePrivateModelIds?.length) {
      (patchData.portal as JsonObject).availablePrivateModelIds = getOidParams(
        portalConfig.availablePrivateModelIds
      );
    }
  }

  /*
   * map string public service, data source, group ids, and assistant ids back to oid objects
   */
  if (patchData.publicServices) {
    const psConfig = patchData.publicServices as PublicServiceConfig;
    const accessList = getAccessListOidParams(patchData.publicServices as JsonObject);

    if (psConfig.blockAction !== 'redirect-url') {
      psConfig.redirectUrl = '';
    }

    patchData.publicServices = {
      ...psConfig,
      ...accessList,
    };
  }

  if (patchData.contextDataSources) {
    patchData.contextDataSources = getAccessListOidParams(
      patchData.contextDataSources as JsonObject
    );
  }

  if (patchData.assistants) {
    patchData.assistants = getAccessListOidParams(patchData.assistants as JsonObject);
  }

  const hasGroups = Array.isArray(patchData.groups);
  if (hasGroups) {
    patchData.groups = getOidParams(patchData.groups as string[]);
  }

  const response = await patchOne(COLLECTION, filter, patchData);
  const updated = response === null;

  if (updated && hasGroups) {
    syncGroupsToPolicy(new PolicyModel({ id: policyId, groups: data.groups }));
  }

  return response === null;
};

/*
 * Given a policy with a list of applied groups, ensure that any applied group
 * also lists the policy in its array of policies, and any group which is not
 * applied does not list the policy.
 */
export const syncGroupsToPolicy = async (policy: PolicyModel): Promise<boolean> => {
  const { groups, id } = policy;
  const policyId = id;

  const currentGroups = await getGroupsByPolicyId(policyId);
  const updates: Promise<UpdateResponse>[] = [];

  // list of groups that do have the policies
  currentGroups.forEach(({ id: groupId, policies }) => {
    // this will always return > -1, but ts can't figure that out :-/
    const policyIndex = policies.findIndex((id) => id === policyId);

    // not in the list? remove the policy from the group
    if (!groups.includes(groupId) && policyIndex > -1) {
      const updatedPolicies = cloneDeep(policies);
      updatedPolicies.splice(policyIndex, 1);
      updates.push(patchGroup(groupId, { policies: getOidParams(updatedPolicies) }));
    }
  });

  // add the policy to groups which need it
  if (groups.length) {
    const updateGroups = await getGroupsByIds(groups);
    updateGroups.forEach(({ id: groupId, policies }) => {
      if (!policies.includes(policyId)) {
        policies.push(policyId);
        updates.push(patchGroup(groupId, { policies: getOidParams(policies) }));
      }
    });
  }

  const response = await Promise.all(updates);

  return response === null;
};

export const deletePolicy = async (policyId: string): Promise<DeleteResponse> => {
  const filter = {
    _id: getOidParam(policyId),
    ...getOrgFilter(),
  };

  const deleted = await deleteOne(COLLECTION, filter);

  // remove this policy from all groups
  if (deleted) {
    await syncGroupsToPolicy(new PolicyModel({ id: policyId, groups: [] }));
  }

  return { deleted };
};

export const removeGroupFromPolicies = async (groupId: string): Promise<UpdateResponse> => {
  const groupIdParam = getOidParam(groupId);

  const response = await update(
    COLLECTION,
    { groups: groupIdParam },
    { $pull: { groups: groupIdParam } }
  );

  return { updated: response !== null };
};

export const getPolicyMeta = async (policies: PolicyModel[]): Promise<PolicyMetaResult> => {
  const providerIds = flatten(
    policies.map(({ contextDataSources }) => contextDataSources?.enabled || [])
  );

  const privateModelIds = flatten(
    policies.map(({ portal }) => portal?.availablePrivateModelIds || [])
  );

  const agentIds = uniq(flatten(policies.map(({ assistants }) => assistants?.enabled || [])));

  if (!providerIds.length && !privateModelIds.length && !agentIds.length) {
    return [new Map(), new Map(), new Map()];
  }

  const [providers, models, agents] = await Promise.all([
    providerIds.length ? getProvidersByIds(providerIds) : [],
    privateModelIds.length ? getPrivateModelsByIds(privateModelIds) : [],
    agentIds.length ? getAgentsByIds(agentIds) : [],
  ]);

  const contextMap: Map<string, ContextProviderMeta> = new Map();

  (providers as ContextProviderModel[]).forEach((provider) => {
    contextMap.set(provider.id, provider.meta);
  });

  const modelMap: Map<string, PrivateModelModel> = new Map();

  (models as PrivateModelModel[]).forEach((model) => {
    modelMap.set(model.id, model);
  });

  const agentMap: Map<string, AgentMeta> = new Map();

  (agents as AgentModel[]).forEach((agents) => {
    agentMap.set(agents.id, agents.meta);
  });

  return [contextMap, modelMap, agentMap];
};
