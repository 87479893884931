import { JsonObject } from '@/lib/helpers';
import { AlertColor } from '@mui/material';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { ObjectSchema, object } from 'yup';
import useEmitter from './use-emitter';

export type FormMessage = { level: AlertColor; content: string; attention?: boolean };

export type FormHandle<T extends FormikValues> = ReturnType<typeof useFormik<T>> & {
  validationSchema: ObjectSchema<JsonObject>;
  canSubmit: boolean;
  canCancel: boolean;
  formMessage: FormMessage | null;
  setFormMessage: (formMessage: FormMessage) => void;
  loadData: (values: FormikValues) => void;
  handleCancel: () => void;
  updateValidationSchema: (validationSchema: ObjectSchema<JsonObject>) => void;
};

const useFormHandle = <T extends FormikValues>(config: FormikConfig<T>): FormHandle<T> => {
  const { subscribe, unsubscribe, emit } = useEmitter();

  const [formMessage, setFormMessage] = useState<FormMessage | null>({
    level: 'info',
    content: '',
  });

  const [validationSchema, setValidationSchema] = useState<ObjectSchema<JsonObject>>(
    (config.validationSchema || object({})) as ObjectSchema<JsonObject>
  );

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    ...config,
    validationSchema,
  });

  const { isSubmitting, dirty, isValid, validateOnChange, resetForm, initialValues } = formik;

  const canSubmit = !isSubmitting && dirty && (!validateOnChange || isValid);
  const canCancel = !isSubmitting && dirty;

  const loadData = useCallback(
    (values: T) => {
      resetForm({ values });
    },
    [resetForm]
  );

  const handleCancel = useCallback(() => {
    loadData(initialValues);
  }, [loadData, initialValues]);

  const updateValidationSchema = (updatedSchema: ObjectSchema<JsonObject>) => {
    setValidationSchema(updatedSchema);
  };

  const handleTabBlock = useCallback(() => {
    setFormMessage({
      level: 'warning',
      content: 'Please save changes or cancel before leaving the form.',
      attention: true,
    });
  }, []);

  // block navigation when a form is dirty
  useEffect(() => {
    emit('form-change', { dirty });
    if (!dirty) {
      setFormMessage(null);
    }
  }, [emit, dirty]);

  useEffect(() => {
    subscribe('tab-block', handleTabBlock);
    return () => {
      unsubscribe('tab-block', handleTabBlock);
    };
  }, [subscribe, unsubscribe, handleTabBlock]);

  return {
    ...formik,
    validationSchema,
    canSubmit,
    formMessage,
    setFormMessage,
    loadData,
    handleCancel,
    canCancel,
    updateValidationSchema,
  };
};

export default useFormHandle;
